<template>
  <v-container class="my-6">
    <v-dialog v-model="dialogDetail" width="1000" @click:outside="clear">
      <div v-if="dialogDetail">
        <order-detail :order="orderSelected" :cancellation="findCancellation" />
      </div>
    </v-dialog>
    <v-dialog v-model="dialogCancel" width="500" @click:outside="clear">
      <cancelacion-simple
        :orderId="orderSelected.id"
        :orderNumber="orderSelected.numero_orden"
        :cancellationReasons="cancellationReasons"
        @cancellationData="updateCancellationData"
      />
    </v-dialog>
    <v-dialog v-model="dialogAlert" width="500" @click:outside="clear">
      <confirm-alert :question="msgAlert" @answer="updateConfirmation" />
    </v-dialog>
    <v-card>
      <v-card-title>Mis pedidos</v-card-title>
      <v-data-table :headers="headers" :items="pedidos">
        <template v-slot:[`item.sub_total`]="{ item }">
          {{ item.sub_total | twoDecimals | commaThousand }}
        </template>
        <template v-slot:[`item.total_impuestos`]="{ item }">
          {{ item.total_impuestos | twoDecimals | commaThousand }}
        </template>
        <template v-slot:[`item.total_orden`]="{ item }">
          {{ item.total_orden | twoDecimals | commaThousand }}
        </template>
        <template v-slot:[`item.costo_envio`]="{ item }">
          {{ item.costo_envio | twoDecimals | commaThousand }}
        </template>
        <template v-slot:[`item.descuento_aplicado`]="{ item }">
          <v-icon v-if="!item.descuento_aplicado" right color="red">
            mdi-close-circle
          </v-icon>
          <v-icon v-if="item.descuento_aplicado" right color="success">
            mdi-checkbox-marked-circle
          </v-icon>
        </template>

        <template v-slot:[`item.accion`]="{ item }" class="px-0 mx-0">
          <v-row justify-center>
            <v-col md="6">
              <v-icon icon title="Ver detalle" @click="openDetail(item)">
                mdi-eye
              </v-icon>
            </v-col>
            <v-col md="6">
              <v-icon icon title="Cancelar Orden" @click="openAlert(item)">
                mdi-cancel
              </v-icon>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.download`]="{ item }" class="px-0 mx-0">
          <v-row>
            <v-col md="6">
              <v-icon
                right
                icon
                tittle="Descargar factura"
                @click="descargarFactura(item)"
                :disabled="item.documentos_factura.length == 0"
              >
                mdi-cloud-download
              </v-icon>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script src="../../../../node_modules/jszip"></script>

<script>
import { ApiAuth } from "@/api_utils/axios-base";
import { mapState } from "vuex";
import { tFilters } from "@/mixins/filters.js";
import { objectsMixin } from "@/mixins/objectsValidations.js";
import { Api } from "../../../api_utils/axios-base";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { fs } from "fs";
export default {
  metaInfo: {
    title: 'Mis pedidos'
  },
  mixins: [tFilters, objectsMixin],
  components: {
    OrderDetail: () => import("@/components/utils/OrderDetail"),
    CancelacionSimple: () =>
      import("@/components/utils/SimpleForms/CancelacionSimple"),
    ConfirmAlert: () => import("@/components/utils/alerts/ConfirmAlert"),
  },
  data() {
    return {
      nombreDocumentoFile: null,
      headers: [
        { text: "Número de Orden", value: "numero_orden", sortable: false },
        {
          text: "Estatus de Orden",
          value: "estatus_orden.dato",
          sortable: false,
        },
        {
          text: "Distribuidor",
          value: "distribuidor.nombre_distribuidor",
          sortable: false,
        },
        { text: "Tipo de Envío", value: "tipo_envio.dato", sortable: false },
        {
          text: "Peso Volumétrico",
          value: "peso_volumetrico",
          sortable: false,
        },
        { text: "Sub Total", value: "sub_total", sortable: false },
        { text: "Total Impuestos", value: "total_impuestos", sortable: false },
        { text: "Costo de Envío", value: "costo_envio", sortable: false },
        { text: "Total Orden", value: "total_orden" },
        { text: "Moneda", value: "moneda.nombre_moneda", sortable: false },
        {
          text: "Descuento Aplicado",
          value: "descuento_aplicado",
          sortable: false,
        },
        { text: "Acción", value: "accion", sortable: false },
        { text: "Descargar Factura", value: "download", sortable: false },
      ],
      pedidos: [],
      direccion_envio: {
        calle: "",
        numero_exterior: "",
        numero_interior: "",
        colonia: "",
        codigo_postal: "",
        ciudad: "",
        estado: "",
        latitud: null,
        longitud: null,
      },
      cancellationData: {},
      cancellationReasons: [],
      dialogDetail: false,
      dialogCancel: false,
      dialogAlert: false,
      orderSelected: {},
      cancellations: [],
    };
  },
  created() {
    this.getPedidos();
    this.getCancellationReasons();
  },
  watch: {
    cancellationData(val) {
      if (!this.objectHasNulls(val)) {
        this.cancelOrder();
      }
    },
  },
  computed: {
    ...mapState("usuario", ["userData"]),
    msgAlert() {
      return this.orderSelected.estatus_orden &&
        this.orderSelected.estatus_orden.dato === "Cancelada"
        ? "La orden ya ha sido cancelada"
        : "Estas seguro de cancelar la orden?";
    },
    findCancellation() {
      return this.cancellations.find(
        (cancellation) => cancellation.orden.id === this.orderSelected.id
      );
    },
  },
  methods: {
    async descargarFactura(orden) {
      const arrayFactura = orden.documentos_factura;
      if (arrayFactura) {
        var zip = new JSZip();
        for (const objetoDocumento of arrayFactura) {
          const urlDocumento = objetoDocumento.documento;
          const nombreArchivo = objetoDocumento.nombre_documento;

          const respuestaGetDoc = await axios({
            url: urlDocumento,
            method: "GET",
            responseType: "blob",
          });
          const respuestaDataDoc = await respuestaGetDoc.data;
          const fileblob = new Blob([respuestaDataDoc]);
          zip.file(nombreArchivo, fileblob, { binary: true });
        }
        const content = await zip.generateAsync({ type: "blob" });
        const currentDate = new Date().getTime();
        const fileName = `Facturas-${currentDate}.zip`;
        return saveAs(content, fileName);
      }
    },
    async getPedidos() {
      if (this.userData?.id) {
        try {
          this.cancellations = [];
          let ordenesResp = await ApiAuth.get(
            `/reportes/api/ordenes-por-usuario/?id_usuario=${this.userData.id}`
          );
          let ordenes = await ordenesResp.data;
          // console.log(ordenes);
          //this.pedidos = ordenes
          // console.log("pedidos: ", this.pedidos);

          const detailsResponse = await ApiAuth.get(
            `/checkout/api/detalle-ordenes-cliente/?id_usuario=${this.userData.id}`
          );
          const detailsData = await detailsResponse.data;

          if (detailsData) {
            // console.log("Si tiene detalles");
            ordenes.forEach((pedido) => {
              const details = detailsData.filter(
                (detail) => pedido.id === detail.orden.id
              );
              if (details.length) {
                pedido.detalles = details;

                const firstDetail = details[0];
                const dir = pedido.cliente.direcciones_entrega.find(
                  (address) => address.id === firstDetail.orden.direccion_envio
                );
                if (dir) {
                  pedido.direccion_envio = dir;
                } else {
                  const predeterminado = pedido.cliente.direcciones_entrega.find(
                    (address) => address.predeterminado === true
                  );
                  if (predeterminado) {
                    pedido.direccion_envio = predeterminado;
                  } else {
                    if (pedido.cliente.direcciones_entrega.length) {
                      pedido.direccion_envio =
                        pedido.cliente.direcciones_entrega[0];
                    } else {
                      pedido.direccion_envio = this.direccion_envio;
                    }
                  }
                }
              } else {
                pedido.detalles = [];
              }
            });
          }
          this.pedidos = ordenes;
          // console.log("pedidos con detalles: ", this.pedidos);

          for (const order of this.pedidos) {
            if (order.estatus_orden.dato === "Cancelada") {
              const cancellation = await this.getOrderCancellation(order);
              if (cancellation) {
                this.cancellations.push(cancellation);
              }
            }
          }
        } catch (error) {
          console.error("error getting orders", error);
        }
      } else {
        console.log("Sesión no inicidada");
      }
    },
    async getCancellationReasons() {
      try {
        const response = await ApiAuth.get(
          "/checkout/api/motivos-cancelacion/"
        );
        const responseData = await response.data;

        if (responseData.length) {
          this.cancellationReasons = responseData;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getOrderCancellation(order) {
      try {
        const response = await ApiAuth.get(
          `/checkout/api/cancelacion-orden/?id_orden=${order.id}`
        );
        const responseData = await response.data;

        // console.log("responseData cancellation: ", responseData);
        return responseData[0];
      } catch (error) {
        console.error("Error Obteniendo cancelacion");
      }
    },
    updateCancellationData(cancellationData) {
      this.cancellationData = { ...cancellationData };
      this.cancellationData.id_usuario = this.userData.id;
      // console.log("CancellationData: ", this.cancellationData);
    },
    updateOrder(order) {
      this.orderSelected = { ...order };
    },
    updateConfirmation(confirmation) {
      this.dialogAlert = false;
      if (
        confirmation &&
        this.orderSelected.estatus_orden.dato !== "Cancelada"
      ) {
        this.openCancel(this.orderSelected);
      }
    },
    openAlert(order) {
      this.updateOrder(order);
      this.dialogAlert = true;
    },
    openCancel(order) {
      this.orderSelected = { ...order };
      this.dialogCancel = true;
    },
    openDetail(order) {
      this.orderSelected = { ...order };
      this.dialogDetail = true;
    },
    clear() {
      this.orderSelected = {
        estatus_orden: "",
        cliente: "",
        distribuidor: {
          datos_contacto: "",
        },
      };
      this.cancellationData = {
        id_orden: null,
      };
    },
    async cancelOrder() {
      try {
        const response = await ApiAuth.post(
          "/checkout/cancelar-orden-cliente",
          this.cancellationData
        );
        const responseData = await response.data;
        if (responseData.error) {
          // Mandar alerta
          setTimeout(
            this.$store.commit("setAlert", {
              show: true,
              msg:
                "Ocurrio un error y no se pudo mandar la solicitud de cancelacion",
              type: "error",
            }),
            1000
          );
          this.clear();
        } else {
          setTimeout(
            this.$store.commit("setAlert", {
              show: true,
              msg:
                "Se envio la solitud de cancelacion. Mantente pendiente por la respuesta del distribuidor",
              type: "success",
            }),
            1000
          );
        }
        this.dialogCancel = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.v-data-table-header tr th {
  background-color: #ff3700 !important;
}
.v-data-table-header tr th span {
  color: white !important;
}
</style>
