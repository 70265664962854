var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"my-6"},[_c('v-dialog',{attrs:{"width":"1000"},on:{"click:outside":_vm.clear},model:{value:(_vm.dialogDetail),callback:function ($$v) {_vm.dialogDetail=$$v},expression:"dialogDetail"}},[(_vm.dialogDetail)?_c('div',[_c('order-detail',{attrs:{"order":_vm.orderSelected,"cancellation":_vm.findCancellation}})],1):_vm._e()]),_c('v-dialog',{attrs:{"width":"500"},on:{"click:outside":_vm.clear},model:{value:(_vm.dialogCancel),callback:function ($$v) {_vm.dialogCancel=$$v},expression:"dialogCancel"}},[_c('cancelacion-simple',{attrs:{"orderId":_vm.orderSelected.id,"orderNumber":_vm.orderSelected.numero_orden,"cancellationReasons":_vm.cancellationReasons},on:{"cancellationData":_vm.updateCancellationData}})],1),_c('v-dialog',{attrs:{"width":"500"},on:{"click:outside":_vm.clear},model:{value:(_vm.dialogAlert),callback:function ($$v) {_vm.dialogAlert=$$v},expression:"dialogAlert"}},[_c('confirm-alert',{attrs:{"question":_vm.msgAlert},on:{"answer":_vm.updateConfirmation}})],1),_c('v-card',[_c('v-card-title',[_vm._v("Mis pedidos")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pedidos},scopedSlots:_vm._u([{key:"item.sub_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaThousand")(_vm._f("twoDecimals")(item.sub_total)))+" ")]}},{key:"item.total_impuestos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaThousand")(_vm._f("twoDecimals")(item.total_impuestos)))+" ")]}},{key:"item.total_orden",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaThousand")(_vm._f("twoDecimals")(item.total_orden)))+" ")]}},{key:"item.costo_envio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("commaThousand")(_vm._f("twoDecimals")(item.costo_envio)))+" ")]}},{key:"item.descuento_aplicado",fn:function(ref){
var item = ref.item;
return [(!item.descuento_aplicado)?_c('v-icon',{attrs:{"right":"","color":"red"}},[_vm._v(" mdi-close-circle ")]):_vm._e(),(item.descuento_aplicado)?_c('v-icon',{attrs:{"right":"","color":"success"}},[_vm._v(" mdi-checkbox-marked-circle ")]):_vm._e()]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify-center":""}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-icon',{attrs:{"icon":"","title":"Ver detalle"},on:{"click":function($event){return _vm.openDetail(item)}}},[_vm._v(" mdi-eye ")])],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-icon',{attrs:{"icon":"","title":"Cancelar Orden"},on:{"click":function($event){return _vm.openAlert(item)}}},[_vm._v(" mdi-cancel ")])],1)],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-icon',{attrs:{"right":"","icon":"","tittle":"Descargar factura","disabled":item.documentos_factura.length == 0},on:{"click":function($event){return _vm.descargarFactura(item)}}},[_vm._v(" mdi-cloud-download ")])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }